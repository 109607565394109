import React, { useRef } from "react";
import Slider from "react-slick";
import TestimonialCard1 from '~components/TestimonialCard1/TestimonialCard1';
import imgT5 from '~assets/image/customer-stories/user4.jpeg';
import imgT7 from '~assets/image/customer-stories/user5.jpeg';
import imgT8 from '~assets/image/customer-stories/user-2.png';
import imgT9 from '~assets/image/customer-stories/user-3.png';
import imgT11 from '~assets/image/customer-stories/user-1.png';
import imgT6 from '~assets/image/customer-stories/Ingrid_k.jpeg';
import imgT2 from '~assets/image/customer-stories/Joanne-R.jpeg';
import imgT10 from '~assets/image/customer-stories/Olivier-L.jpeg';
import imgT3 from '~assets/image/customer-stories/Max-B.jpeg';
import imgT4 from '~assets/image/customer-stories/Devon-S.jpg';
import imgT1 from '~assets/image/customer-stories/Joshua-TB.jpeg';
import img1 from '~assets/image/G2/LeadCapture_MomentumLeader_Leader.svg'
import img2 from '~assets/image/G2/LeadCapture_HighPerformer_Americas_HighPerformer.svg'
import img3 from '~assets/image/G2/LeadCapture_HighPerformer_Small-Business_HighPerformer.svg'
import img4 from '~assets/image/G2/LeadCapture_HighPerformer_Small-Business_Europe_HighPerformer.svg'
import Customerstory from "./style";
import { SuperTag } from "~components";

const items = [
  {
    image: imgT1,
    title: "Fantastic software and customer service!",
    content: "This software is very simple to use and saves us so much time and money! I've been able to grow my business significantly in the past month because of this software - without spending hours trying to connect, message, and follow up with people who never respond. The live chat customer support has been really helpful in resolving all our issues.",
    userlink: "Joshua TB",
    position: "Master Trainer",
  },
  {
    image: imgT2,
    title: "Highly recommend and have recommended to others",
    content:
      "Very easy to set up and use, the support team are so good with any help needed but it is so easy to use. It runs in the background and saves me a huge amount of time.",
    userlink: "Joanne R",
    position: "Director",
  },
  {
    image: imgT3,
    title: "The best value for money marketing tool I've used.",
    content:
      "Takes the grind out of marketing and has a really helpful interface to track responses - it means you can gather meaningful KPI's. It also educated you about using LinkedIn as a marketing platform. I'm able to send out 700 connections automatically, each week, when I want and We-Connect tracks them for me.",
    userlink: "Max B",
    position: "Founder",
  },
  {
    image: imgT4,
    title: "Critical for fundraising and customer discovery",
    content:
      "We-Connect helped us get 50 investor meetings. Every startup founder needs this product. All of my team members have accounts and we're reaching out 150 people a day!",
    userlink: "Devon S",
    position: "President",
  },
  {
    image: imgT5,
    title: "Easy set up",
    content:
      "It's a good system and doesn't require that I have a chrome extension running all the time, open tabs etc. It was easy to search for a group of people I'd like to build a campaign with. The sequenced messages after connection are similarly easy to set up.",
    userlink: "Craig H",
    position: "Client Partner",
  },
  {
  image: imgT6,
  title: "Great product and customer service!",
  content:
    "What a time saver...send customized messages to new or existing contacts in order to stay connected with and grow your network.",
  userlink: "Ingrid K",
  position: "CEO",
  },
  {
  image: imgT7,
  title: "Overwhelmingly valuable",
  content:
    "Easy to use interface. Easy to set up. Huge return on investment",
  userlink: "James C",
  position: "Business Coach",
  },
  {
  image: imgT8,
  title: "The New Linkedofy!",
  content:
    "I use We-Connect primarily for networking and connecting with new clients/business partners. Since day one, I've been completely satisfied with all of their services. I like the ease of use with the automation that occurs even when you're off the computer.",
  userlink: "Emmeline T",
  position: "Tax Reduction Adviser",
  },
  {
  image: imgT9,
  title: "We Connect Is A Wonderful platform",
  content:
    "It has been wonderful. They answer support questions very quickly and are always adding new features to We-Connect. I have been with them since the beginning and now have over 8,300 connections, it works!!",
  userlink: "Larry A",
  position: "Tax Reduction Adviser",
  },
  {
    image: imgT10,
    title: "Great tool to help me grow my network",
    content:
      "I like that it works all by itself while I do other stuff. My network is constantly growing.",
    userlink: "Olivier L",
    position: "Tax Reduction Adviser",
  },
  {
    image: imgT11,
    title: "Great tool to build up your LinkedIn presence or do business development, rockstar customer support",
    content:
      "Allows you to automate Outreach to your target market in a personalized way, great helper. Good UI, easy to automate your work, reliable, saves you a lot of time and gives you lots of benefits in business development/sales on LinkedIn.",
    userlink: "Moritz S",
    position: "Tax Reduction Adviser",
  },
  
];

const Testimonial = ({ className, ...rest }) => {
  const elSlider = useRef();

  const slickSettings = {
    autoplay: false,
    slidesToShow: 3,
    arrows: false,
    infinite: true,
    dots: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 468,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <>
      <Customerstory className={className} {...rest}>
        <div className="container">
          {/* <!-- Section Title --> */}
          <div className="mb-8 mb-lg-5 mb-md-5 mb-xs-4">
            <div className="row align-items-center justify-content-center">
              <div className="col-lg-7 col-md-9">
                <div
                  className="text-center text-md-left"
                  data-aos="fade-right"
                  data-aos-delay="500"
                >
                  
                  <Customerstory.Title as="h2">
                  <SuperTag value="Trusted by 10,000+ users"/>
                  </Customerstory.Title>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- End Section Title --> */}
          {/* <!-- Badge Section --> */}
          <div className="mb-8 mb-lg-7 mb-md-5 mb-xs-2 bd">
            <div className="row align-items-center justify-content-center">
            <div className=" col-md-2 col-xs-6 text-center">
              <a title="Users love we-connect.io on G2" href="https://www.g2.com/products/we-connect-io/reviews?utm_source=rewards-badge" target="_blank"><img style={{width: 120}} alt="we-connect.io is a leader in Small-Business Lead Capture on G2" src="https://www.g2.com/shared-assets/product-badges/users-love-us.svg" /></a>
              </div>
              <div className=" col-md-2 col-xs-6 text-center">
              <a title="we-connect.io is a leader in Americas Lead Intelligence on G2" href="https://www.g2.com/products/we-connect-io/reviews?utm_source=rewards-badge" target="_blank"><img style={{width: 120}} alt="we-connect.io is a leader in Americas Lead Intelligence on G2" src={img2} /></a>
              </div>
              <div className=" col-md-2 col-xs-6 text-center">
              <a title="we-connect.io is a leader in Small-Business Lead Intelligence on G2" href="https://www.g2.com/products/we-connect-io/reviews?utm_source=rewards-badge" target="_blank"><img style={{width: 120}} alt="we-connect.io is a leader in Small-Business Lead Intelligence on G2" src={img3} /></a>
              </div>
              
              <div className=" col-md-2 col-xs-6 text-center mb-3 mb-xs-0">
              <a title="we-connect.io is a leader in Small-Business Lead Capture on G2" href="https://www.g2.com/products/we-connect-io/reviews?utm_source=rewards-badge" target="_blank"><img style={{width: 120}} alt="Users love we-connect.io on G2" src={img4} /></a>
              </div>
            </div>
            </div>


          {/* <!-- End Badge Section --> */}
          {/* <!-- category slider --> */}
          <div className="row">
            <div className="col-12">
              <div className="testimonial-one">
                <Slider
                  ref={elSlider}
                  {...slickSettings}
                  css={`
                    .slick-dots {
                      display: flex !important;
                    }
                  `}
                >
                  {items.map((item, index) => (
                    <div className="single-category focus-reset" key={index}>
                      <TestimonialCard1 options={item} />
                    </div>
                  ))}
                </Slider>
              </div>
            </div>
          </div>
          {/* <!-- End category slider --> */}
        </div>
      </Customerstory>
    </>
  );
};

export default Testimonial;
